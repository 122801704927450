@import "../../styles/variables.scss";

.container {
  background-image: url('../../assets/images/home.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .serie {
    transform: translateY(-30px);
    animation-delay: .3s;
    animation-fill-mode: backwards;
    @include animation-mixin-y(serie, 100px, -30px);
    animation-name: serie;
    animation-duration: $duration;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1800px) {
      transform: translateY(-70px);
      @include animation-mixin-y(serie, 100px, -70px);
    }

    h4 {
      font-family: "Montserrat-Regular";
      text-transform: uppercase;
      font-weight: normal;
      font-size: 28px;
      margin: 0 0 10px 0;

      @media screen and (min-width: $md-screen) {
        font-size: 38px;
      }

      @media screen and (min-width: 1800px) {
        font-size: 48px;
      }
    }
    .theme {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 500px;
 
      background: rgba(34, 35, 75, 0.69);
      min-height: 180px;
      border-radius: 90px;
      padding: 30px 30px;  

      @media screen and (min-width: $md-screen) {
        min-width: 700px;
        padding: 40px 40px;
      }

      @media screen and (min-width: $lg-screen) {
        padding: 40px 60px;  
      }

      p {
        text-transform: uppercase;
        font-family: "Montserrat-Regular";
        font-weight: normal;
        font-size: 24px;
        margin: 0px;
      }
      h2 {
        @include title-2;
        text-align: center;
        margin: 0px 0px 10px 0px;
      }
    }
    .points {
      transform: translateY(-35px);
      min-width: 220px;
      min-height: 64px;
      background: $white;
      border-radius: 70px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 1800px) {
        min-width: 240px;
        min-height: 74px;
      }

      h3 {
        text-align: center;
        color: $black;
        font-size: 30px;
        margin: 0px;
        span {
          @include bold;
          font-size: 30px;
        }
  
        @media screen and (min-width: 1800px) {
          font-size: 40px;
          >span{ font-size: 40px; }
        }
      }
    }
    .question {
      font-family: "Montserrat-Bold";
      font-size: 18px;
      text-transform: uppercase;
      font-weight: normal;
      transform: translateY(-35px);
      margin: $margin-sm 0 0 0;
    }
  }
}
