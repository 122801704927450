@import "../../styles/variables.scss";

.container {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: $black;
  opacity: 0;
  transition: all 0.6s ease-in-out;
  pointer-events: none;
  .container-image {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &.is-active {
    pointer-events: none;
    opacity: 1;
  }
  &.is-muted {
    background-color: rgba($black, 0.9);
    pointer-events: all;
    opacity: 1;
  }
  .audio-animation {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  .legend {
    position: absolute;
    z-index: 1;
    animation-delay: 0.1;
    animation-name: legend;
    animation-duration: $duration;
    @include animation-mixin-x(legend, -200px, 0px);
    background-color: rgba($dark, 0.8);
    font-size: 30px;
    margin: 30px;
    padding: 30px 50px;
    // min-width: 650px;
    border-radius: 120px;
    text-transform: uppercase;
    p {
      margin: 0px;
    }
    span {
      @include bold;
      font-size: 30px;
    }
  }
  button {
    position: absolute;
    top: calc(50% - 80px);
    left: calc(50% - 140px);
    @include btn;
    background-color: $white;
    min-width: 300px;
    min-height: 74px;
    background: $white;
    border-radius: 70px;
    @include label;
    font-size: 24px;
    text-transform: uppercase;
  }

  .youtube {
    background-color: red;
    > div > div {
      position: relative;
      width: 300% !important; /* enlarge beyond browser width */
      left: -100%; /* center */
    }
  }
}

.soundIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  .soundWave {
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transform: scale(2);

    .bar {
      display: block;
      width: 2px;
      margin-right: 10px;
      height: 90px;
      background: rgba(255, 255, 255, 1);
      animation: sound 0ms -800ms linear infinite alternate;
      transition: height 0.8s;
    }

    .bar:nth-child(20n + 1) {
      height: 2px;
      animation-duration: 474ms;
    }
    .bar:nth-child(20n + 2) {
      height: 10px;
      animation-duration: 433ms;
    }
    .bar:nth-child(20n + 3) {
      height: 18px;
      animation-duration: 407ms;
    }
    .bar:nth-child(20n + 4) {
      height: 26px;
      animation-duration: 458ms;
    }
    .bar:nth-child(20n + 5) {
      height: 30px;
      animation-duration: 400ms;
    }
    .bar:nth-child(20n + 6) {
      height: 32px;
      animation-duration: 427ms;
    }
    .bar:nth-child(20n + 7) {
      height: 34px;
      animation-duration: 441ms;
    }
    .bar:nth-child(20n + 8) {
      height: 36px;
      animation-duration: 419ms;
    }
    .bar:nth-child(20n + 9) {
      height: 40px;
      animation-duration: 487ms;
    }
    .bar:nth-child(20n + 10) {
      height: 46px;
      animation-duration: 442ms;
    }
    .bar:nth-child(20n + 11) {
      height: 2px;
      animation-duration: 474ms;
    }
    .bar:nth-child(20n + 12) {
      height: 10px;
      animation-duration: 433ms;
    }
    .bar:nth-child(20n + 13) {
      height: 18px;
      animation-duration: 407ms;
    }
    .bar:nth-child(20n + 14) {
      height: 26px;
      animation-duration: 458ms;
    }
    .bar:nth-child(20n + 15) {
      height: 30px;
      animation-duration: 400ms;
    }
    .bar:nth-child(20n + 16) {
      height: 32px;
      animation-duration: 427ms;
    }
    .bar:nth-child(20n + 17) {
      height: 34px;
      animation-duration: 441ms;
    }
    .bar:nth-child(20n + 18) {
      height: 36px;
      animation-duration: 419ms;
    }
    .bar:nth-child(20n + 19) {
      height: 40px;
      animation-duration: 487ms;
    }
    .bar:nth-child(20n + 20) {
      height: 46px;
      animation-duration: 442ms;
    }

    @keyframes sound {
      0% {
        opacity: 0.5;
        height: 6px;
      }
      100% {
        opacity: 1;
        height: 46px;
      }
    }
  }
}
