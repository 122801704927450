@import '../../styles/variables.scss';

.container {
  background-image: url('../../assets/images/home.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .serie {
    transform: translateY(-50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-name: serie;
    animation-duration: $duration;
    @include animation-mixin-y(serie, 100px, -50px);

    @media screen and (min-width: 1800px) {
      transform: translateY(-70px);
      @include animation-mixin-y(serie, 100px, -70px);
    }

    h2 {
      font-family: "Montserrat-Bold";
      font-weight: normal;
      font-size: 38px;
      line-height: 45px;
      letter-spacing: 10px;
      margin: 0px;
      text-align: center;

      @media screen and (min-width: 1800px) {
        font-size: 48px;
        line-height: 55px;
      }
      
    }
    h3 {
      @include title-4;
      font-size: 24px;
      margin: 10px 10px 15px 10px;
      text-transform: uppercase;
    }
    .theme {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 750px;
      max-width: 80%;
      min-height: 180px;
      background: rgba(34, 35, 75, 0.69);
      border-radius: 200px;
      padding: $margin-md 0;

      p {
        text-transform: uppercase;
        font-family: "Montserrat-Regular";
        font-weight: normal;
        font-size: 24px;
        margin: 0 0 $margin-sm 0;
      }

      .hours{
        text-transform: lowercase;
      }
  

      h2 {
        @include title-2;
        text-transform: uppercase;
        letter-spacing: 10px;
        margin: 0px;
        padding: 0 50px;
      }
    }
  }
}