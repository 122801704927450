@import '../../styles/variables.scss';

.container {
  background-image: url('../../assets/images/home.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-80px);
    animation-name: box;
    animation-duration: $duration;
    @include animation-mixin-y(box, 100px, -80px);

    @media screen and (min-width: 1100px) {
      transform: translateY(-100px);
      @include animation-mixin-y(box, 100px, -100px);
    }  

    @media screen and (min-width: $md-screen) {
      transform: translateY(-50px);
      @include animation-mixin-y(box, 100px, -50px);
    }

    .date {
      position: relative;
      display: inline-block;
      h2 {
        text-align: center;
        @include title-1;
        margin: 0px;
      }
      img {
        position: absolute;
        &.left {
          left: -70px;
          bottom: -50px;
          transform: scale(0.8) rotate(180deg);

          @media screen and (min-width: 1700px) {
            transform: scale(1) rotate(180deg);
          }
        }
        &.right {
          right: -60px;
          top: -30px;
          transform: scale(0.8);
          @media screen and (min-width: 1700px) {
            transform: scale(1);
          }
        }
      }
    }

    .hours{
      text-transform: lowercase;
    }

    h1 {
      @include title-1;
      text-align: center;
      margin: 8px;
      text-transform: uppercase;
      &.cotton {
        letter-spacing: 12px;
        margin: 0px;
        @media screen and (min-width: $md-screen) {
          margin: 8px;
        }
      }
    }
  }
}