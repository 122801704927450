@import "../../styles/variables.scss";

.container {
  background-image: url("../../assets/images/home.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .serie {
    transform: translateY(-50px);
    animation-name: box;
    animation-duration: $duration;
    @include animation-mixin-y(box, 100px, -50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    @media screen and (min-width: 1800px) {
      transform: translateY(-70px);
      @include animation-mixin-y(serie, 100px, -70px);
    }

    h1 {
      @include title-big-1;
      letter-spacing: 25px;
      margin: 0px;
    }
    .theme {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 500px;
      background: rgba(34, 35, 75, 0.69);
      min-height: 180px;
      border-radius: 90px;
      padding: 30px 30px;  

      @media screen and (min-width: $md-screen) {
        padding: 20 40px;
        min-width: 700px;
      }

      @media screen and (min-width: $lg-screen) {
        padding: 40px 60px;  
      }


      p {
        text-transform: uppercase;
        font-family: "Montserrat-Regular";
        font-weight: normal;
        font-size: 24px;
        margin: 0px;
        text-align: center;
      }
      h2 {
        @include title-2;
        margin: 0px;
        text-align: center;
      }
    }
  }
}
