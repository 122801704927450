@import "../../styles/variables.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100vh - 202px);

  @media screen and (min-width: 1100px) {
    height: calc(100vh - 232px);
  }

  @media screen and (min-width: $md-screen) {
    height: calc(100vh - 242px);
  }
  .first {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    .title {
      display: flex;
      align-items: center;
      width: 100%;
      .logo {
        img {
          margin: 0 20px;
          max-height: 50px;
          @media screen and (min-width: $md-screen) {
            margin: 0 30px;
            max-height: 60px;
          }

          @media screen and (min-width: $lg-screen) {
            max-height: 70px;
          }
        }
      }
      h1 {
        font-family: "Montserrat-Bold";
        font-size: 30px;
        @media screen and (min-width: $md-screen) {
          font-size: 34px;
        }

        @media screen and (min-width: $lg-screen) {
          font-size: 44px;
        }
      }
    }

    .top {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      .team {
        padding: 20px;
        height: 100%;
        max-height: calc(100% / 3);
        // overflow: hidden;
        // @media screen and (min-width: $md-screen) {
        //   height: 80px;
        // }

        .separator {
          width: 1px;
          height: 100%;
          background-color: rgba(218, 218, 218, 0.4);
        }

        h2 {
          display: block;
          border-right: none;
          font-size: 45px;
          margin: 0;
          padding: 0 20px;

          &.egality{
            text-align: center;
            min-width: 104px;
          }  

          @media screen and (min-width: $md-screen) {
            font-size: 50px;
          }

          @media screen and (min-width: 1500px) {
            font-size: 60px;
          }
        }

        h3 {
          font-size: 18px;
          margin: 0;

          @media screen and (min-width: $lg-screen) {
            font-size: 20px;
          }
          @media screen and (min-width: 1500px) {
            font-size: 26px;
          }
        }

        .container-image{
          position: relative;
          display: flex;
          width: 40%;
          padding-top: calc(40% * (9 / 16));
          min-width: 40%;

          @media screen and (min-aspect-ratio: 16/9) {
            width: 33%;
            padding-top: calc(33% * (9 / 16));
            min-width: 33%;
          }

          background-color: $black;
          align-items: center;
          justify-content: center;
          border-radius: 7px;
          overflow: hidden;

          .container-lottie{
            position: absolute;
            top: 50%;
            height: 50%;
            width: 50%;
            transform: translateY(-50%);
            svg{
              object-fit: contain;
            }
          }

          svg,
          img {
            // transform: translate(-50%,-50%);
            width: 100%;
            max-width: 100%;
            object-fit: contain;
            height: 100%;
            object-fit: cover;
            object-position: center;
            // display: none;
            // height: 150px;
  
            // @media screen and (min-height: 1080px) {
            //   height: 150px;
            // }
  
            // @media screen and (min-height: 1080px) {
            //   height: 150px;
            // }
          }

          img{
            position: absolute;
            top: 0;
          }

        }


      }
    }
  }

  .all {
    width: 50%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: wrap column;
    background: linear-gradient(90deg, #272755 0%, rgba(51, 48, 103, 0) 25%);
    padding: 10px 30px;

    .team {
      padding: 0px 20px;
      margin: 0px 0;
      width: 50%;
      height: calc(100% / 5);
      // box-sizing: content-box;

      @media screen and (min-width: 1320px) {
        height: calc(100% / 6);
      }

      h2 {
        margin: 0;
        font-size: 35px;
        border-right: 1px solid rgba(218, 218, 218, 0.4);
        padding-right: 20px;
        min-width: 67px;

        &.egality{
          text-align: center;
          min-width: 86px;
        }

        @media screen and (min-width: $md-screen) {
          font-size: 40px;
          min-width: 74px;
        }

        @media screen and (min-width: 1500px) {
          font-size: 50px;
          min-width: 86px;
        }
      }

      h3 {
        // margin-left: 20px;
        margin: 0px 0 0px 20px;
        font-size: 16px;
        @media screen and (min-width: $md-screen) {
          font-size: 18px;
        }

        @media screen and (min-width: 1500px) {
          font-size: 20px;
        }
      }
    }
  }

  .team {
    display: flex;
    align-items: center;
    font-family: "Montserrat-Bold";
    animation: slideIn 0.2s ease-in-out;
    animation-fill-mode: both;
    @include animation-mixin-x(slideIn, -100px, 0px);

    h3 {
      font-family: "Montserrat-Bold";
      font-weight: normal;

      .score {
        font-family: "Montserrat-SemiBold";
      }

      .pts {
        font-family: "Montserrat-Regular";
        text-transform: lowercase;
      }
    }
  }
}
