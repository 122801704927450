@import "../../styles/variables.scss";

.container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: rgba(#1b1c3f, 0.7);
  @include title-3;

  height: 202px;

  @media screen and (min-width: 1100px) {
    height: 232px;
  }

  @media screen and (min-width: $md-screen) {
    height: 242px;
  }

  .lot {
    display: flex;
    justify-content: flex-start;
    position: relative;
    border-bottom: 1px solid rgba(236, 236, 236, 0.3);
    width: 100%;

    height: 52px;

    .col {
      padding: 5px 20px;
      margin: 12px 0px;
      display: flex;
      align-items: center;
      @media screen and (min-width: 1800px) {
        padding: 10px 20px;
      }
      p {
        margin: 0px;
        padding: 0px;
        white-space: nowrap;
        &.label {
          @include label;
          font-size: 18px;
          @media screen and (min-width: 1800px) {
            font-size: 20px;
          }
          text-transform: uppercase;
        }
      }
      .number {
        height: 36px;
        width: 36px;
        border-radius: 20px;
        margin-right: 10px;
        background-color: $dark-lighter;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:not(:first-child) {
        border-left: 1px solid rgba(236, 236, 236, 0.3);
      }
    }

    .col:first-child {
      max-width: 192px;
      width: 100%;
    }

    .col:not(:first-child) {
      max-width: calc(33.33% - (192px / 3));
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
      }
    }
  }
  .row {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    height: calc(100% - 52px);

    .client {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      // width: 20%;
      background-color: $black;
      overflow: hidden;
      h3 {
        width: 100%;
        margin: 0 0 5px 0;
        padding: 0 30px 0 15px;
        box-sizing: border-box;
        text-transform: uppercase;
        font-size: 22px;
        @media screen and (min-width: $md-screen) {
          font-size: 28px;
        }
      }
      p {
        width: 100%;
        margin: 0;
        padding: 0 30px 0 15px;
        box-sizing: border-box;
        opacity: 0.4;
        font-size: 18px;
        @media screen and (min-width: $md-screen) {
          font-size: 20px;
        }
      }
      img {
        // position: absolute;
        // top:50%;
        // left:50%;
        height: 100%;
        // transform: translate(-50%,-50%);
        object-fit: contain;
      }
    }
    .quote {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin: 0 35px;
      p {
        font-family: "MontSerrat-Regular";
        margin: 0px 0px 0px 0px;
        padding: 0px 44px;
        text-align: center;
        font-size: 19px;
        line-height: 21px;
        @media screen and (min-width: $md-screen) {
          font-size: 22px;
          line-height: 24px;
        }
        @media screen and (min-width: $lg-screen) {
          font-size: 30px;
          line-height: 32px;
        }
        > strong {
          font-family: "MontSerrat-Bold";
        }
      }
      .left {
        position: absolute;
        top: 30px;
        left: 0px;
      }
      .right {
        position: absolute;
        bottom: 30px;
        right: 0px;
        transform: scale(-1, 1);
      }
    }
    .image {
      position: relative;
      width: 20%;
      overflow: hidden;
      background-color: $black;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.quiz {
  position: absolute;
  margin: 40px;
  animation: slideInQuiz 0.6s ease-in-out;
  animation-fill-mode: both;
  @include animation-mixin-x(slideInQuiz, -100px, 0px);
  h4 {
    @include bold;
    font-size: 28px;
    margin: 0px;
  }
  p {
    @include title-4;
    margin: 15px 0px;
  }
}

.logo {
  position: absolute;
  top: 20px;
  left: calc(50% - 79px);

  @include label;

  img {
    width: 148px;
    @media screen and (min-width: $md-screen) {
      width: 158px;
    }

    @media screen and (min-width: 1800px) {
      width: 178px;
    }
  }
}

// .qrcode {
// position: absolute;
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;
// margin: 40px 10px;
// right: 0px;
// bottom: 21vh;
// color: $white;
// animation: slideInQr 0.6s ease-in-out;;
// animation-fill-mode: both;
// @include animation-mixin-x(slideInQr, 100px, 0px);
// width: 200px;
// height: 200px;

// @media screen and (min-width: 1200px) {
//   bottom: 23vh;
//   margin: 40px 20px;
// }

// @media screen and (min-width: 1800px) {
//   bottom: 23vh;
//   margin: 40px;
// }
// }
