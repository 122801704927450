@mixin title-big-1 {
	font-family: "Montserrat-Bold";
  font-weight: normal;
  font-size: 70px;
  line-height: 91px;

  @media screen and (min-width: $sm-screen) {
    font-size: 80px;
    line-height: 101px;
  }

  @media screen and (min-width: $md-screen) {
    font-size: 90px;
    line-height: 111px;
  }

  @media screen and (min-width: 18) {
    font-size: 100px;
    line-height: 121px;
  }

}

@mixin title-1 {
	font-family: "Montserrat-Bold";
  font-weight: normal;
  font-size: 60px;
  line-height: 1em;

  @media screen and (min-width: $lg-screen) {
    font-size: 60px;
    line-height: 1em;
  }

  @media screen and (min-width: 1500px) {
    font-size: 70px;
    line-height: 75px;
  }

}

@mixin title-2 {
  font-family: "Montserrat-Bold";
  font-weight: normal;
  font-size: 40px;
  line-height: 55px;

  // @media screen and (min-width: $sm-screen) {
  //   font-size: 55px;
  //   line-height: 70px;
  // }

  @media screen and (min-width: $md-screen) {
    font-size: 40px;
    line-height: 55px;
  }


  @media screen and (min-width: 1800px) {
    font-size: 70px;
    line-height: 85px;
  }
}

@mixin title-3 {
	font-family: "Montserrat-Bold";
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  @media screen and (min-width: 1800px) {
    font-size: 26px;
    line-height: 31px;  
  }

}

@mixin title-4 {
	font-family: "Montserrat-SemiBold";
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
}

@mixin label {
	font-family: "Montserrat-SemiBold";
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

@mixin bold {
	font-family: "Montserrat-Bold";
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

@mixin header-item {
	font-family: "Montserrat-Regular";
  font-weight: normal;
  font-size: 15px;
  color: $white;
  transition: color .3s ease-in-out;
  &:hover{
      color : $primary-color;
  }
}

// NUNITO

@mixin text {
	font-family: "Montserrat-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color : $black;
}

@mixin text-small {
	font-family: "Montserrat-Regular";
  font-weight: normal;
  font-size: 13px;
  color : $text-color-light;
}

@mixin text-button {
	font-family: "Montserrat-SemiBold";
  font-weight: normal;
  font-size: 14px;
}
